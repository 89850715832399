import { LogoColorExtraction } from '../pages/Admin/LogoColorExtraction';

function LogoColorExtractionRoute() {
  return <LogoColorExtraction />;
}

export const Component = LogoColorExtractionRoute;

export function clientLoader() {
  return null;
}
